/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { useLocation } from "@reach/router"

function SEO({ description, lang, meta, title, metaTitle, metaDescription, metaImage }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            twitterUsername
            siteUrl
            image
          }
        }
      }
    `
  )
  const { pathname } = useLocation()
  const siteUrl = site.siteMetadata.siteUrl + pathname
  const imageUrl = site.siteMetadata.image
  const siteDescription = description || site.siteMetadata.description
  const titleTemplate = title || site.siteMetadata.title
  const titleMeta = metaTitle || site.siteMetadata.title
  const twitterUser = site.siteMetadata.twitterUsername
  const canonical = pathname === '/' ? `${site.siteMetadata.siteUrl}${pathname}` : null
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={titleTemplate}
      titleTemplate={titleTemplate}
      link={
        canonical
          ? [
            {
              rel: "canonical",
              href: canonical,
            },
          ]
          : []
      }
      meta={[
        {
          name: `description`,
          content: siteDescription,
        },
        {
          name: `image`,
          content: imageUrl,
        },
        {
          property: `og:title`,
          content: titleMeta,
        },
        {
          property: `og:description`,
          content: siteDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: imageUrl,
        },
        {
          property: `og:url`,
          content: siteUrl,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:creator`,
          content: twitterUser,
        },
        {
          name: `twitter:title`,
          content: titleMeta,
        },
        {
          name: `twitter:image`,
          content: imageUrl,
        },
        {
          name: `twitter:description`,
          content: siteDescription,
        },
      ].concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `es`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  metaTitle: PropTypes.string,
  metaDescription: PropTypes.string,
  metaImage: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
}

export default SEO
