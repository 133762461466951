import React from "react"
import { Link } from "gatsby-theme-material-ui"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => {
  const location = {
    pathname: '/'
  }
  return (
    <Layout location={location}>
      <SEO title="Página no encontrada" />
      <div style={{ textAlign: 'center', paddingTop: '3rem' }}>
        <h1>Página no encontrada</h1>
        <p><Link to='/'>Volver a home</Link></p>
      </div>
    </Layout>
  )
}

export default NotFoundPage
